<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import moment from 'moment/moment'
import { PAST_DUE_DAYS_LIMIT } from '@/constants'
import SubscriptionSettingsCancel from '@/components/subscriptionSettings/SubscriptionSettingsCancel.vue'
import AddChangePaymentMethodLink from '@/components/subscriptionSettings/AddChangePaymentMethodLink.vue'
import SubscriptionSettingsPromoCode from '@/components/subscriptionSettings/SubscriptionSettingsPromoCode.vue'
defineEmits(['updateSubscription', 'updatePaymentMethod'])

const store = useStore()
const isPastDue = computed(() => store.getters['workspace/isPastDue'])
const isPaused = computed(() => store.getters['workspace/isPaused'])
const isUnpaid = computed(() => store.getters['workspace/isUnpaid'])
const isInactive = computed(() => store.getters['workspace/isInactive'])
const isFrozen = computed(() => store.getters['workspace/isFrozen'])
const lastWorkspaceInvoiceUrl = computed(() => store.getters['subscription/lastWorkspaceInvoiceUrl'])
const currentPeriodStarts = computed(() => store.getters['workspace/currentPeriodStarts'])
const pastDueDays = computed(()=>{
  if (!currentPeriodStarts.value || !isPastDue.value) return null
  const currentPeriodStartsDate = moment(currentPeriodStarts.value).add(PAST_DUE_DAYS_LIMIT, 'day')
  const currentDate = moment()
  return currentPeriodStartsDate.diff(currentDate, 'days')
})

</script>

<template>
  <a-typography-title
    :level="5"
    style="margin-bottom: 20px;"
  >
    {{ $t('components.subscriptionSettings.manageSubscriptionLink') }}
  </a-typography-title>
  <a-alert
    v-if="isPastDue"
    show-icon
    type="warning"
    style="margin-bottom: 16px;"
  >
    <template #message>
      {{ $t('components.subscriptionSettings.lastPaymentFail', {days: pastDueDays}, pastDueDays) }}
      <br>
      <a-typography-text
        class="underline-link"
        @click="$emit('updatePaymentMethod')"
      >
        {{ $t('components.subscriptionSettings.updatePaymentMethodLink') }}
      </a-typography-text>
    </template>
  </a-alert>
  <a-alert
    v-else-if="isUnpaid"
    show-icon
    type="warning"
    style="margin-bottom: 16px;"
  >
    <template #message>
      {{ $t('components.subscriptionSettings.subscriptionSuspended') }}
      <br>
      <a-typography-link
        class="underline-link"
        @click="$emit('updateSubscription')"
      >
        {{ $t('components.subscriptionSettings.upgradeLink') }}
      </a-typography-link>
    </template>
  </a-alert>
  <a-alert
    v-else-if="isPaused"
    show-icon
    type="warning"
    style="margin-bottom: 16px;"
  >
    <template #message>
      {{ $t('components.subscriptionSettings.subscriptionPaused') }}
      <br>
      <a-typography-link
        class="underline-link"
        href="mailto:support@kitcast.tv"
      >
        {{ $t('components.subscriptionSettings.contactSupportLink') }}
      </a-typography-link>
    </template>
  </a-alert>
  <a-alert
    v-else-if="isInactive"
    show-icon
    type="warning"
    style="margin-bottom: 16px;"
  >
    <template #message>
      {{ $t('components.subscriptionSettings.subscriptionDoesntExist') }}
      <br>
      <a-typography-link
        class="underline-link"
        @click="$emit('updateSubscription')"
      >
        {{ $t('components.subscriptionSettings.upgradeLink') }}
      </a-typography-link>
    </template>
  </a-alert>
  <a-alert
    v-else-if="isFrozen"
    show-icon
    type="warning"
    style="margin-bottom: 16px;"
  >
    <template #message>
      {{ $t('components.subscriptionSettings.subscriptionFrozen') }}
      <br>
      <a-typography-link
        class="underline-link"
        href="mailto:support@kitcast.tv"
      >
        {{ $t('components.subscriptionSettings.contactSupportLink') }}
      </a-typography-link>
    </template>
  </a-alert>
  <a-space
    direction="vertical"
    style="flex: 1;"
  >
    <a-typography-link
      @click="$emit('updateSubscription')"
    >
      {{ $t('components.subscriptionSettings.addMoreLicensesLink') }}
    </a-typography-link>
    <AddChangePaymentMethodLink />
    <a-typography-link
      :disabled="!lastWorkspaceInvoiceUrl"
      download
      :href="lastWorkspaceInvoiceUrl"
    >
      {{ $t('components.subscriptionSettings.downloadLatestInvoiceLink') }}
    </a-typography-link>
  </a-space>
  <SubscriptionSettingsPromoCode />
  <SubscriptionSettingsCancel v-if="!isUnpaid && !isInactive && !isPaused && !isFrozen" />
</template>

<style scoped lang="less">

</style>

