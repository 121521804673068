<template>
  <transition
    mode="out-in"
    name="fade"
    :duration="300"
  >
    <div v-if="step==='first'">
      <AuthHeading
        :title="$t('components.signupForm.titleStep1')"
        :subtitle="$t('components.signupForm.subtitleStep1')"
      />
      <a-form
        v-if="step==='first'"
        ref="emailFormRef"
        class="signup-form"
        layout="vertical"
        :model="formState"
        style="margin-top: 32px;"
        :rules="rules"
        @submit.prevent="proceedRegistration"
      >
        <a-form-item
          :label="$t('components.signupForm.emailLabel')"
          name="email"
        >
          <a-input
            v-model:value="formState.email"
            placeholder="name@company.com"
            autofocus
          />
        </a-form-item>
        <a-form-item
          :label="$t('components.signupForm.passwordLabel')"
          name="password"
        >
          <a-input
            v-model:value="formState.password"
            :placeholder="$t('components.signupForm.passwordPlaceholder')"
            type="password"
          />
        </a-form-item>
        <a-form-item style="margin-bottom: 32px; margin-top: 16px;">
          <a-button
            type="primary"
            block
            html-type="submit"
            size="large"
          >
            {{ $t('components.signupForm.continueButtonText') }}
          </a-button>
        </a-form-item>
      </a-form>
      <div
        v-if="step==='first'"
        class="form-footer"
      >
        <div><a-divider>{{ $t('components.signupForm.or') }}</a-divider></div>
        <a-row
          :gutter="[8, 8]"
          type="flex"
          justify="center"
        >
          <a-col :span="8">
            <a-button
              size="large"
              block
              :disabled="loading"
              @click="openLoginWithGoogleWindow"
            >
              <template #icon>
                <GoogleOutlined />
              </template>
              Google
            </a-button>
          </a-col>
          <a-col :span="8">
            <a-button
              size="large"
              block
              :disabled="loading"
              @click="openLoginWithMicrosoftWindow"
            >
              <template #icon>
                <WindowsOutlined />
              </template>
              Microsoft
            </a-button>
          </a-col>
        </a-row>
        <div class="spacer" />
        <p style="text-align: center; margin-top: 24px;">
          {{ $t('components.signupForm.accountExists') }}
          <router-link :to="{name: 'Login'}">
            <a-typography-link>
              {{ $t('components.signupForm.loginLink') }}
            </a-typography-link>
          </router-link>
        </p>
      </div>
    </div>
    <div
      v-else
      style="flex: 1; display: flex; flex-direction: column"
    >
      <AuthHeading
        :title="$t('components.signupForm.titleStep2')"
        :subtitle="$t('components.signupForm.subtitleStep2')"
      />
      <a-form
        ref="detailsFormRef"
        layout="vertical"
        :model="formState"
        style="flex: 1; display: flex; flex-direction: column"
        :rules="rules"
        @submit.prevent="handleSubmit"
      >
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.firstNameLabel')"
              name="firstName"
            >
              <a-input
                v-model:value="formState.firstName"
                :placeholder="$t('components.signupForm.firstNamePlaceholder')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.lastNameLabel')"
              name="lastName"
            >
              <a-input
                v-model:value="formState.lastName"
                :placeholder="$t('components.signupForm.lastNamePlaceholder')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.phoneLabel')"
              name="phone"
            >
              <vue-tel-input
                ref="phoneInput"
                v-model="formState.phone"
                :class="{'has-error': !phoneObject?.valid && formState.phone}"
                :input-options="{ placeholder: $t('components.signupForm.phonePlaceholder') }"
                mode="international"
                @on-input="handlePhoneChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.jobTitleLabel')"
              name="jobTitle"
            >
              <a-input
                v-model:value="formState.jobTitle"
                :placeholder="$t('components.signupForm.jobTitlePlaceholder')"
                autofocus
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          :label="$t('components.signupForm.industryLabel')"
          name="companyIndustry"
        >
          <a-select
            v-model:value="formState.companyIndustry"
            :placeholder="$t('components.signupForm.industryPlaceholder')"
            :options="industryList.map((key) => ({ value: key, label: $t(`components.signupForm.industries.${key}`) }))"
          />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.companyNameLabel')"
              name="companyName"
            >
              <a-input
                v-model:value="formState.companyName"
                :placeholder="$t('components.signupForm.companyNamePlaceholder')"
                autofocus
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.companyWebsiteLabel')"
              name="companyWebsite"
            >
              <a-input
                v-model:value="formState.companyWebsite"
                :placeholder="$t('components.signupForm.companyWebsitePlaceholder')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.companySizeLabel')"
              name="companySize"
            >
              <a-select
                v-model:value="formState.companySize"
                :placeholder="$t('components.signupForm.companySizePlaceholder')"
                :options="companySizesList.map((key) => ({ value: key, label: $t(`components.signupForm.companySizes.${key}`) }))"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('components.signupForm.screensQuantityLabel')"
              name="desiredDevicesCount"
            >
              <a-input-number
                v-model:value="formState.desiredDevicesCount"
                min="1"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="form-footer bottom">
          <div class="form-actions">
            <a-space>
              <a-button
                type="default"
                size="large"
                :disabled="loading"
                @click="goBack()"
              >
                {{ $t('components.signupForm.backButtonText') }}
              </a-button>
              <a-button
                :loading="loading"
                type="primary"
                html-type="submit"
                size="large"
              >
                {{ $t('components.signupForm.continueButtonText') }}
              </a-button>
            </a-space>
          </div>
        </div>
      </a-form>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, inject, reactive, ref, toRaw, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { error } from '@/utils'
import { GoogleOutlined, WindowsOutlined } from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { COMPANY_SIZE_LIST, INDUSTRY_LIST } from '@/constants'
import AuthHeading from '@/components/authModal/AuthHeading.vue'
import { openLoginWithGoogleWindow } from '@/helpers/Google'
import { openLoginWithMicrosoftWindow } from '@/helpers/Microsoft'
import { isURL } from 'validator'
import { cloneDeep } from 'lodash'

const EDUCATION_INDUSTRY='EDUCATION'

export default defineComponent({
  name: 'SignupForm',
  components: { AuthHeading, GoogleOutlined, WindowsOutlined },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const userId = computed(() => store.getters['auth/userId'])
    const isEducation = route.query?.hasOwnProperty('education')
    const { t } = useI18n()
    const emailFormRef = ref()
    const detailsFormRef = ref()
    const loading = ref(false)
    const phoneObject = ref()
    const step = ref('first')
    const gtm = inject('gtm')
    const formState = reactive({
      email: '',
      password: '',
      companyName: '',
      companyIndustry: isEducation ? EDUCATION_INDUSTRY : null,
      companySize: COMPANY_SIZE_LIST[0],
      companyWebsite: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      phone: '',
      desiredDevicesCount: 1
    })

    const rules = computed( ()=> {
      return {
        email: [{
          required: true,
          type: 'email',
          trigger: 'blur',
          message: t('components.signupForm.emailInvalidError')
        }],
        companyName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.signupForm.companyNameInvalidError')
        }],
        companyIndustry: [{
          required: true,
          trigger: 'blur',
          message: t('components.signupForm.industryInvalidError')
        }],
        jobTitle: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.signupForm.jobTitleInvalidError')
        }],
        companyWebsite: [{
          validator: (_,value) => {
            if (!value) return Promise.reject()
            return isURL(value) ? Promise.resolve() : Promise.reject()
          },
          required: true,
          trigger: 'blur',
          message: t('components.signupForm.companyWebsiteInvalidError')
        }],
        firstName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.signupForm.firstNameInvalidError')
        }],
        lastName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.signupForm.lastNameInvalidError')
        }],
        phone: [{
          required: true,
          trigger: 'blur',
          message: t('components.signupForm.phoneInvalidError')
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: t('components.signupForm.passwordInvalidError')
        },
        {
          trigger: 'blur',
          min: 8,
          message: t('components.signupForm.passwordTooShortError')
        },
        {
          trigger: 'blur',
          max: 20,
          message: t('components.signupForm.passwordTooLongError')
        }]
      }
    })

    const proceedRegistration = () => {
      setTimeout(async () => {
        try {
          await emailFormRef.value.validate()
          step.value = 'second'
        } catch (e) {

        }
      })
    }

    const handleSubmit = () => {
      if (!phoneObject.value?.valid) return
      setTimeout(async () => {
        try {
          await detailsFormRef.value.validate()
        } catch (e) {
          return
        }
        loading.value = true
        const input = cloneDeep(toRaw(formState))
        input.email = input.email?.toLowerCase()
        input.phone = phoneObject.value?.number || input.phone
        await store.dispatch('auth/register', { input }).then(() => {
          gtm.push({ event: 'RegistrationForm', action: 'show', label: 'success', userId: userId.value })
          router.push({ name: 'Home' })
        }).catch((e) => {
          const originalErrorMessage = e.graphQLErrors?.[0]?.extensions?.originalError?.message?.[0]
          const errorMessage = originalErrorMessage ? t(`errors.codes["${originalErrorMessage}"]`) : e.message
          error(errorMessage)
        }).finally(() => {
          loading.value = false
        })
      })
    }

    const handlePhoneChange = (number, phoneObj) => {
      phoneObject.value = phoneObj
    }

    const goBack = () => {
      step.value = 'first'
    }

    watchEffect(() => {
      const eventLabel = step.value === 'first' ? 'step1' : step.value === 'second' ? 'step2' : null
      if (eventLabel) {
        gtm.push({ event: 'RegistrationForm', action: 'show', label: eventLabel })
      }
    })

    return {
      step,
      formState,
      rules,
      emailFormRef,
      detailsFormRef,
      phoneObject,
      loading,
      companySizesList: COMPANY_SIZE_LIST,
      industryList: INDUSTRY_LIST,
      openLoginWithGoogleWindow,
      openLoginWithMicrosoftWindow,
      goBack,
      handleSubmit,
      proceedRegistration,
      handlePhoneChange
    }
  }
})
</script>

<style lang="less">
.form-footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  &.bottom {
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.signup-form {
  .ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
    display: none;
  }
}
</style>
