<script setup>
import moment from 'moment'

const date = moment().format('MMM D YYYY')
const freeDevicesNumber = 2
</script>

<template>
  <a-typography-text
    v-if="false"
    type="secondary"
  >
    {{ $t('components.subscriptionSettingsInfoCardFreeDevices.freeDevices', {count: freeDevicesNumber, date}, freeDevicesNumber) }}
  </a-typography-text>
</template>

<style scoped lang="less">

</style>
