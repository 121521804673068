import { message } from 'ant-design-vue'
import { AVATARS, COLORS_PALETTE, MIME_TYPES_MAP, PROTOCOL_REGEXP } from '@/constants'
import moment from 'moment'
import convertLegacyToken from 'ant-design-vue/es/theme/convertLegacyToken'
import assets from '@/assets/composer.json'
import dayjs from 'dayjs'

// color regex (^[a-zA-Z]+$)|(#(?:[0-9a-f]{2}){2,4}|#[0-9a-f]{3}|(?:rgba?|hsla?)\((?:\d+%?(?:deg|rad|grad|turn)?(?:,|\s)+){2,3}[\s\/]*[\d\.]+%?\))

export const success = (content = 'Done!', duration = 2) => {
  message.success({
    content,
    duration
  })
}

export const error = (content = 'Error', duration = 2) => {
  message.error({
    content,
    duration
  })
}

export const warn = (content = 'Error', duration = 2) => {
  message.warn({
    content,
    duration
  })
}

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`
  } else {
    return `rgb(${r},${g},${b})`
  }
}
export const getArrayRandomElement = (arr) => {
  if (arr && arr.length) {
    return arr[Math.floor(Math.random() * arr.length)]
  }
}

export const getParentKey = (key, tree) => {
  let parentKey

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]

    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

export const formatDate = (value) => {
  if (value) {
    return moment(String(value)).format('MMM Do YYYY HH:mm')
  }
}

export const formatMimeType = (input) => MIME_TYPES_MAP[input] || input

export const formatFileSize = (size) => {
  if (!size) return ''
  if (parseInt(size) === 0) return 0 + 'B'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + '' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const colorShade = (col, amt) => {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

  r = Math.max(Math.min(255, r), 0).toString(16)
  g = Math.max(Math.min(255, g), 0).toString(16)
  b = Math.max(Math.min(255, b), 0).toString(16)

  const rr = (r.length < 2 ? '0' : '') + r
  const gg = (g.length < 2 ? '0' : '') + g
  const bb = (b.length < 2 ? '0' : '') + b

  return `#${rr}${gg}${bb}`
}

export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600)
  const mins = ~~((duration % 3600) / 60)
  const secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let output = ''

  output += '' + hrs + ':' + (mins < 10 ? '0' : '')

  output += '' + mins + ':' + (secs < 10 ? '0' : '')
  output += '' + secs
  return output
}

export const normalizeUrl = (url) => url.trim().replace(PROTOCOL_REGEXP, '').replace(/(\/)$/, '')

export const getUrlProtocol = (url) => {
  const [urlProtocol] = url.match(PROTOCOL_REGEXP)
  return urlProtocol || null
}

export const isLt2M = size => size / 1024 / 1024 < 2

export const randomPaletteColor = () => getArrayRandomElement(COLORS_PALETTE)

export default {
  hexToRGB,
  success,
  error,
  warn,
  randomPaletteColor
}

const GROUP_TO_ICON_MAP = {
  folder: 'FolderOutlined',
  file: 'FileOutlined',
  compressed: 'FileZipOutlined',
  text: 'FileTextOutlined',
  word: 'FileWordOutlined',
  image: 'FileImageOutlined',
  audio: 'AudioOutlined',
  video: 'YoutubeOutlined',
  font: 'FileOutlined',
  pdf: 'FilePdfOutlined',
  presentation: 'FilePptOutlined',
  spreadsheet: ''

}

const getMimetypeGroup = (mimetype) => {
  let group
  switch (mimetype) {
    case 'application/octet-stream':
    case 'application/vnd.visio':
    case 'application/x-freearc':
    case 'application/ogg':
    case 'application/x-csh':
    case 'application/java-archive':
    case 'application/vnd.apple.installer+xml':
    case 'application/x-sh':
    case 'application/x-shockwave-flash':
    case 'application/x-httpd-php':
      group = 'file'
      break
    case 'application/x-7z-compressed':
    case 'image/bmp':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/gzip':
    case 'application/zip':
    case 'application/x-tar':
    case 'application/vnd.rar':
      group = 'compressed'
      break
    case 'text/css':
    case 'text/csv':
    case 'text/xml':
    case 'text/plain':
    case 'text/html':
    case 'text/calendar':
    case 'text/javascript':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/rtf':
    case 'application/epub+zip':
    case 'application/vnd.amazon.ebook':
    case 'application/ld+json':
    case 'application/json':
    case 'application/x-abiword':
      group = 'text'
      break
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      group = 'word'
      break
    case 'image/gif':
    case 'image/vnd.microsoft.icon':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/tiff':
    case 'image/webp':
      group = 'image'
      break
    case 'audio/midi':
    case 'audio/x-midi':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/opus':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/3gpp':
    case 'audio/3gpp2':
    case 'audio/aac':
      group = 'audio'
      break
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/mp2t':
    case 'video/webm':
    case 'video/x-msvideo':
      group = 'video'
      break
    case 'font/otf':
    case 'font/ttf':
    case 'font/woff2':
    case 'font/woff':
    case 'application/vnd.ms-fontobject':
      group = 'font'
      break
    case 'application/pdf':
      group = 'pdf'
      break
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      group = 'presentation'
      break
    case 'application/xhtml+xml':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.mozilla.xul+xml':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      group = 'spreadsheet'
      break
    case 'folder':
      group = 'folder'
      break
    default:
      group = 'file'
      break
  }
  return group
}

export const getIconNameByMimetype = (mimetype) => GROUP_TO_ICON_MAP[getMimetypeGroup(mimetype)] || ''

export const updateEntityById = (object, objectProperty, entity) => {
  if (!object[objectProperty] || !object[objectProperty].length) return []
  if (object[objectProperty].findIndex(obj => obj.id === entity.id) === -1) {
    return [...object[objectProperty], entity]
  }
  const index = object[objectProperty].findIndex(obj => obj.id === entity.id)
  if (index !== -1) {
    return [...(index > 0 ? object[objectProperty].slice(0, index) : []), entity, ...object[objectProperty].slice(index + 1)]
  }
}

export const getAffectedPlaylistsCount = (e) => {
  const [errorObj] = e.graphQLErrors
  return errorObj.extensions?.forceChanges?.toDeleteWidgetFromPlaylistsCount
}

export function isColorLight (hexCode) {
  hexCode = hexCode.replace('#', '')
  if (hexCode.length === 3) {
    hexCode.split('').map((a) => a + a).join('')
  }
  const cR = parseInt(hexCode.substr(0, 2), 16)
  const cG = parseInt(hexCode.substr(2, 2), 16)
  const cB = parseInt(hexCode.substr(4, 2), 16)

  return (((cR * 299) + (cG * 587) + (cB * 114)) / 100) >= 1275
}


export function isInputOrTextarea (el) {
  return ['input' , 'textarea'].includes(el.tagName.toLowerCase())
}

export function pluralizationUa(choice, choicesLength) {
  if (choicesLength === 2) {
    return choice === 1 ? 0 : 1
  }

  if (choice === 0) {
    return 0
  }

  const endsWithOne = choice % 10 === 1
  const endsWithTwoToFour = [2,3,4].includes(choice % 10)
  const teen = choice > 10 && choice <= 20
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && endsWithTwoToFour) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

export const sortByValueAsc = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

function getUserIdHashCode(userId) {
  // Calculate a simple hash code based on the characters of the user ID
  let hashCode = 0;
  for (let i = 0; i < userId.length; i++) {
    const char = userId.charCodeAt(i);
    hashCode = (hashCode << 5) - hashCode + char;
  }

  return hashCode;
}

export function getUserIdToAvatarIndex(userId, avatarsCount) {
  const maxAvatarIndex = avatarsCount - 1;
  // Calculate a numeric value from the user ID using a hash function
  const hashCode = Math.abs(getUserIdHashCode(userId));

  // Normalize the hash code to fit within the range [1, maxAvatarIndex]
  return 1 + (hashCode % maxAvatarIndex);
}

export function getUserAvatarPlaceholder(userId) {
  return userId ? require(`@/assets/avatars/${AVATARS[getUserIdToAvatarIndex(userId, AVATARS.length)]}`) : ''
}

export function loadExternalScript(src, path) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    if (path){
      script.setAttribute('data-path', path);
    }
    script.async = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };
    document.head.appendChild(script);
  });
}

export function unloadExternalScript(src) {
  const scripts = document.querySelectorAll(`script[src="${src}"]`);
  scripts.forEach(script => {
    script.remove()
  })
}

export async function loadJson(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
}


export async function isImageValid(url) {
  try {
    const response = await fetch(url, {
      method: 'HEAD', // Используем HEAD, чтобы не скачивать весь контент,
      mode: 'no-cors'
    });
    return response.ok; // true если статус ответа в диапазоне 200-299
  } catch (error) {
    console.error('Error checking image:', error);
    return false;
  }
}

export const mapTokenToVars = (token) => {
  return convertLegacyToken(token)
}

export const getSecretFromOtpUrl = (url) => {
  try {
    const otpUrl = new URL(url)
    const params = new URLSearchParams(otpUrl.search) || ''
    return params.get('secret')
  } catch (error) {
    console.error('Error parsing OTP URL:', error)
    return null
  }
}


export const getIsAmPmFormatFromLocalStorage = () => {
  const value = localStorage.getItem('isAmPmFormat')
  return value ? JSON.parse(value) : true
}

export const setIsAmPmFormatToLocalStorage = (value) => {
  localStorage.setItem('isAmPmFormat', JSON.stringify(value))
}

export function ifIsYoutubeLink (input) {
  const regex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
  return regex.test(input)
}

export function extractVideoIdFromYoutubeLink(link) {
  const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = regex.exec(link);
  return match ? match[1] : null;
}

export function getComposerAssets () {
  let mappedAssets = null

  function mapAssets() {
    const mAssets = {}
    Object.keys(assets).forEach(cat => {
      mAssets[cat] = assets[cat].map(item => {
        return {
          path: process.env.VUE_APP_STATIC_ASSETS + item.path,
          thumbnail: process.env.VUE_APP_STATIC_ASSETS + item.thumbnail,
        }
      })
    })
    return mAssets
  }

  return function () {
    if (!mappedAssets) {
      mappedAssets = mapAssets()
    }
    return mappedAssets
  }
}

export const formatTimeForDuration = (time) => {
  if (!time) return ''
  return dayjs(fancyTimeFormat(time), 'HH:mm:ss')
}

export const formatTimeToDuration = ({ $H, $m, $s }) => {
  return $H * 3600 + $m * 60 + $s
}
