<script setup>
import { ref } from 'vue'
import { success, error } from '@/utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const promoCode = ref('')
const loading = ref(false)
const handleSubmit = () => {
  if(!promoCode.value) {
    return
  }
  loading.value = true
  setTimeout(() => {
    loading.value = false
    success(t('components.subscriptionSettingsPromoCode.successMessage'))
    error(t('components.subscriptionSettingsPromoCode.errorMessage'))
  }, 1000)
  console.log('promoCode:', promoCode.value)
}
</script>

<template>
  <a-form
    v-if="false"
    @submit="handleSubmit"
  >
    <div style="display: flex; gap: 8px; align-items: center; margin: 4px 0; white-space: nowrap;">
      <a-typography-text>Promo Code:</a-typography-text>
      <a-input-group compact>
        <a-input
          v-model:value="promoCode"
          :loading="loading"
          style="width: 150px;"
          :placeholder="$t('components.subscriptionSettingsPromoCode.promoCodePlaceholder')"
          @keydown.enter.prevent="handleSubmit"
        />
        <a-button
          type="primary"
          :loading="loading"
          @click="handleSubmit"
        >
          {{ $t('components.subscriptionSettingsPromoCode.applyButtonText') }}
        </a-button>
      </a-input-group>
    </div>
  </a-form>
</template>

<style scoped lang="less">

</style>
