<template>
  <a-modal
    :open="visible"
    :title="$t('components.exportSlideModal.title')"
    width="400px"
    @ok="onOk"
    @cancel="onClose"
  >
    <a-form layout="vertical">
      <a-form-item :label="$t('components.exportSlideModal.selectPlaylistLabel')">
        <a-tree-select
          ref="treeSelectRef"
          v-model:value="selectedKeys"
          tree-checkable
          :show-checked-strategy="SHOW_CHILD"
          multiple
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :placeholder="$t('components.exportSlideModal.selectPlaylistPlaceholder')"
          allow-clear
          tree-default-expand-all
          :tree-data="groupsTree"
          :open="open"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button
        key="submit"
        type="primary"
        :disabled="disabled"
        @click="onOk"
      >
        {{ $t('components.exportSlideModal.okButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import { TreeSelect } from 'ant-design-vue'
import { useStore } from 'vuex'

const SHOW_CHILD = TreeSelect.SHOW_CHILD

export default defineComponent({
  name: 'ExportSlideModal',
  props: {
    visible: Boolean,
    playlistId: String
  },
  emits: ['update:visible', 'select', 'close'],
  setup (props, { emit }) {
    const store = useStore()

    const smartGroupsEnabled = computed(()=> store.getters['workspace/smartGroupsEnabled'])
    const standardGroupsTree = computed(() => store.getters['groups/availableStandardGroupsTree'](
        { playlistIdToExclude: playlistId.value }))
    const smartGroupsTree = computed(() => store.getters['groups/availableSmartGroupsTree'](
        { playlistIdToExclude: playlistId.value }))

    const availableStandardGroups = computed(() => store.getters['groups/availableStandardGroups'])
    const availableSmartGroups = computed(() => store.getters['groups/availableSmartGroups'])
    const availableGroups = computed(()=>[...availableStandardGroups.value, ...availableSmartGroups.value])

    // const groupsTree = computed(() => store.getters['groups/availableGroupsTree']({ playlistIdToExclude: playlistId.value }))

    const groupsTree = computed(() => {
      if (smartGroupsEnabled.value && smartGroupsTree.value.length) {
        if (standardGroupsTree.value?.length) {
          return [{
            title: 'Groups',
            key: 'groups',
            value: 'groups',
            selectable: false,
            checkable: false,
            children : standardGroupsTree.value
          },
            {
              title: 'Smart Groups',
              key: 'smart-groups',
              value: 'smart-groups',
              selectable: false,
              checkable: false,
              children : smartGroupsTree.value
            }]
        }
        else {
          return smartGroupsTree.value
        }
      }
      else {
        return standardGroupsTree.value
      }
    })

    const playlistId = toRef(props, 'playlistId')
    const visible = toRef(props, 'visible')
    const open = ref(false)
    const treeSelectRef = ref()

    const playlists = computed(() => {
      return availableGroups.value.reduce((a, b) => {
        return [...a, ...b.playlists.map(p => {
          return {
            playlistId: p.id,
            zoneIndex: 0
          }
        })]
      }, [])
    })
    const selectedKeys = ref([])

    const disabled = computed(() => selectedKeys.value?.length === 0)

    const onOk = () => {
      open.value = false
      emit('update:visible', false)
      emit('select', playlists.value.filter(p => selectedKeys.value.includes(p.playlistId)))
      selectedKeys.value = []
    }

    const onClose = () => {
      open.value = false
      emit('update:visible', false)
      emit('close')
      selectedKeys.value = []
    }
    watch(() => visible.value, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          open.value = true
          treeSelectRef.value.focus()
        }, 350)
      }
    })

    return {
      SHOW_CHILD,
      treeSelectRef,
      groupsTree,
      selectedKeys,
      disabled,
      open,
      onOk,
      onClose
    }
  }
})
</script>

<style scoped>

</style>
