<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const trialDaysLeft = computed(() => store.getters['workspace/trialDaysLeft'])
const allDevicesNumber = computed(()=>store.getters['devices/allDevicesNumber'])

</script>

<template>
  <a-card>
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t('components.subscriptionSettingsInfoCardTrial.planName') }} ({{ trialDaysLeft ? $t('components.subscriptionSettingsInfoCardTrial.trialEndsIn', {days: trialDaysLeft}, trialDaysLeft ) : $t('components.subscriptionSettingsInfoCardTrial.trialEnded') }})</a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettingsInfoCardTrial.screensConnected', {count: allDevicesNumber}) }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
</template>

<style scoped lang="less">

</style>
