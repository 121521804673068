import {
  ACCEPT_INVITE_BY_ID,
  ACCEPT_INVITE_BY_TOKEN,
  CHANGE_USER_EMAIL,
  CREATE_CHECKOUT_SESSION,
  DELETE_AVATAR_MEDIA,
  DELETE_SESSION_BY_ID,
  DISABLE_2FA,
  FINALIZE_2FA_SETUP,
  LOGIN_USER,
  LOGIN_USER_GOOGLE_SSO,
  LOGIN_USER_MICROSOFT_SSO,
  LOGIN_USER_OIDC_SSO,
  LOGOUT_USER,
  PASS_TWO_FACTOR_CHECK,
  PREPARE_2FA_SETUP,
  REGISTER_USER,
  REJECT_INVITE_BY_ID,
  REJECT_INVITE_BY_TOKEN,
  REQUEST_USER_EMAIL_CHANGE,
  REQUEST_USER_PASSWORD_RESET,
  RESEND_EMAIL_VERIFICATION,
  RESET_USER_PASSWORD,
  REVERT_USER_EMAIL,
  SET_WORKSPACE_ID_FOR_SESSION,
  SUBMIT_ENTERPRISE_REQUEST_FORM,
  UPDATE_PROFILE,
  UPLOAD_AVATAR_MEDIA,
  VERIFY_EMAIL
} from '@/graphql/mutations'
import {
  CHECK_SESSION_STATUS,
  GET_PENDING_INVITE_BY_TOKEN,
  GET_PROFILE_AVATAR,
  GET_WORKSPACE_ID_BY_SHORT_ID,
  GET_WORKSPACE_PUBLIC_INFO_BY_WHITE_LABEL,
  LIST_MY_PENDING_INVITES,
  LIST_SESSIONS,
  LOGGED_IN_USER
} from '@/graphql/queries'
import ApolloClient, { recreateWsLink } from '@/apollo'
import router from '@/router'
import { DEFAULT_ACCENT_COLOR, LOCALES_MAP } from '@/constants'
import { initIntercom, shutDownIntercom } from '@/intercom'
import { getUserAvatarPlaceholder, error } from '@/utils'
import { handleError } from '@/helpers/ErrorHandler'
import { i18n } from '@/i18n'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('auth-token') || null,
    loggingIn: false,
    avatarLoading: false,
    user: {},
    sessionsList: null,
    sessionsLoading: false,
    authStatus: false,
    logo: null,
    favicon: null,
    accentColor: null,
    windowBlurTime: null,
    workspaceDomain: null,
    redirectingToV1: false
  },
  actions: {
    async register ({ commit, dispatch }, authDetails) {
      const { data } = await ApolloClient.mutate({ mutation: REGISTER_USER, variables: { ...authDetails } })
      const token = data.registerUser.accessToken
      commit('SET_TOKEN', token)
      await dispatch('initUser')
    },
    async registerByInvite ({ commit, dispatch }, { inviteToken, input, workspaceId }) {
      const { data } = await ApolloClient.mutate({ mutation: REGISTER_USER, variables: { input } })
      const token = data.registerUser.accessToken
      commit('SET_TOKEN', token)
      await dispatch('acceptInviteByToken', inviteToken)
      await dispatch('initUser', { workspaceId })
    },
    async login ({ commit, dispatch, getters }, authDetails) {
      const whitelabel = getters.workspaceDomain
      authDetails = {
        ...authDetails,
        ...(whitelabel ? {whitelabel} : null)
      }
      try {
        commit('SET_LOGGING_IN_STATUS', true)
        const { data: { loginUser } } = await ApolloClient.mutate({ mutation: LOGIN_USER, variables: authDetails })
        const { accessToken: token, preferableWorkspaceId } = loginUser
        commit('SET_TOKEN', token)
        await dispatch('initUser', {preferableWorkspaceId})
        commit('SET_LOGGING_IN_STATUS', false)
      } catch (e) {
        commit('SET_LOGGING_IN_STATUS', false)
        const extensions = e.graphQLErrors?.[0]?.extensions
        if (extensions?.requires2FA) {
          return extensions
        }
        try {
          await dispatch('loginToV1', { extensions, e })
        }
        catch (e) {
          handleError(e)
        }
      }
    },
    async loginToV1 ({ commit, dispatch, getters, state }, { extensions, e }) {
      const v1AccessToken = extensions.v1AccessToken
      if (!extensions || !extensions?.code === 'USER_EXISTS_IN_V1' || !v1AccessToken) {
        throw e
      }
      state.redirectingToV1 = true
      const dashboardUrl = process.env.VUE_APP_V1_DASHBOARD_URL || 'https://dashboard.kitcast.tv'
      const escapedToken = encodeURIComponent(v1AccessToken)
      location.href = `${dashboardUrl}?v1Token=${escapedToken}`
      throw new Error(i18n.global.t(`errors.codes.REDIRECT_TO_V1`))
    },
    async loginToCanva ({ commit, dispatch, getters }, authDetails) {
      commit('SET_LOGGING_IN_STATUS', true)
      try {
        const { data: { loginUser } } = await ApolloClient.mutate({
          mutation: LOGIN_USER,
          variables: authDetails
        })
        const token = loginUser.accessToken
        commit('SET_TOKEN_ONCE', token)
        commit('SET_LOGGING_IN_STATUS', false)
      } catch (e) {
        commit('SET_LOGGING_IN_STATUS', false)
        const extensions = e.graphQLErrors?.[0]?.extensions
        if (extensions?.requires2FA) {
          return extensions
        }
        handleError(e)
      }
    },
    async loginWith2Fa ({ commit, dispatch, getters }, { preAuthToken, twoFactorCode }) {
      try {
        commit('SET_LOGGING_IN_STATUS', true)
        const { data: { passTwoFactorCheck } } = await ApolloClient.mutate({ mutation: PASS_TWO_FACTOR_CHECK, variables: { preAuthToken, twoFactorCode } })
        const token = passTwoFactorCheck.accessToken
        commit('SET_TOKEN', token)
        await dispatch('initUser')
        commit('SET_LOGGING_IN_STATUS', false)
      } catch (e) {
        commit('SET_LOGGING_IN_STATUS', false)
        handleError(e)
      }
    },
    async acceptInviteByToken ({}, token) {
      return ApolloClient.mutate({ mutation: ACCEPT_INVITE_BY_TOKEN, variables: { token } })
    },
    async getUserPendingInvites () {
      try {
        const { data: { listMyPendingInvites } } =  await ApolloClient.query({ query: LIST_MY_PENDING_INVITES, fetchPolicy: 'no-cache' })
        return listMyPendingInvites
      }
      catch (e) {
        handleError(e)
      }

    },
    async acceptInviteById ({}, id) {
      return ApolloClient.mutate({ mutation: ACCEPT_INVITE_BY_ID, variables: { id } })
    },
    async rejectInviteByToken ({}, token) {
      return ApolloClient.mutate({ mutation: REJECT_INVITE_BY_TOKEN, variables: { token } })
    },
    async rejectInviteById ({}, id) {
      return ApolloClient.mutate({ mutation: REJECT_INVITE_BY_ID, variables: { id } })
    },
    async resendEmailVerification () {
      return ApolloClient.mutate({ mutation: RESEND_EMAIL_VERIFICATION })
    },
    async prepare2Fa ({}, input) {
      try {
        const { data: { prepare2faSetup: { otpUrl } } } = await ApolloClient.mutate({ mutation: PREPARE_2FA_SETUP, variables: input })
        return otpUrl
      }
      catch (e) {
        handleError(e)
      }
    },
    async disable2Fa ({commit}, input) {
      try {
        await ApolloClient.mutate({ mutation: DISABLE_2FA, variables: input })
        await commit('UPDATE_USER', { is2faSet: false })
      }
      catch (e) {
        handleError(e)
      }
    },
    async finalize2faSetup ({commit}, twoFactorCode) {
      try {
        const {data: { finalize2faSetup }} = await ApolloClient.mutate({ mutation: FINALIZE_2FA_SETUP, variables: {twoFactorCode} })
        if (finalize2faSetup) {
          return await commit('UPDATE_USER', { is2faSet: true })
        }
        else {
          throw new Error('Code is incorrect')
        }
      }
      catch (e) {
        handleError(e)
      }
    },
    async checkIfSessionIsActive () {
      await ApolloClient.query({ query: CHECK_SESSION_STATUS, fetchPolicy: 'no-cache' })
    },
    async loginWithGoogle ({ commit, dispatch, rootState }, input) {
      input = {
        ...input,
        ...(rootState.whitelabel ? { whitelabel: rootState.whitelabel } : {})
      }
      try {
        commit('SET_LOGGING_IN_STATUS', true)
        const { data: { loginUserSsoGoogle } } = await ApolloClient.mutate({ mutation: LOGIN_USER_GOOGLE_SSO, variables: input })
        const token = loginUserSsoGoogle.accessToken
        commit('SET_TOKEN', token)
        await dispatch('initUser')
        commit('SET_LOGGING_IN_STATUS', false)
      } catch (e) {
        commit('SET_LOGGING_IN_STATUS', false)
        const extensions = e.graphQLErrors?.[0]?.extensions
        try {
          await dispatch('loginToV1', { extensions, e })
        }
        catch (e) {
          handleError(e)
        }
      }
    },
    async loginWithMicrosoft ({ commit, dispatch, rootState }, input) {
      const scopes = (process.env.VUE_APP_MICROSOFT_SCOPES || '').split(',')
      input = {
        ...input,
        ...(rootState.whitelabel ? { whitelabel: rootState.whitelabel } : {}),
        scopes
      }
      try {
        commit('SET_LOGGING_IN_STATUS', true)
        const { data: { loginUserSsoMicrosoft } } = await ApolloClient.mutate({ mutation: LOGIN_USER_MICROSOFT_SSO, variables: input })
        const token = loginUserSsoMicrosoft.accessToken
        commit('SET_TOKEN', token)
        await dispatch('initUser')
        commit('SET_LOGGING_IN_STATUS', false)
      } catch (e) {
        commit('SET_LOGGING_IN_STATUS', false)
        const extensions = e.graphQLErrors?.[0]?.extensions
        try {
          await dispatch('loginToV1', { extensions, e })
        }
        catch (e) {
          handleError(e)
        }
      }
    },
    async loginWithOIDC ({ commit, dispatch, rootState }, input) {
      try {
        commit('SET_LOGGING_IN_STATUS', true)
        const { data: { loginUserSsoOidc } } = await ApolloClient.mutate({ mutation: LOGIN_USER_OIDC_SSO, variables: input })
        const token = loginUserSsoOidc.accessToken
        commit('SET_TOKEN', token)
        await dispatch('initUser', { skipWorkspaceSessionSet: true })
        commit('SET_LOGGING_IN_STATUS', false)
      } catch (e) {
        commit('SET_LOGGING_IN_STATUS', false)
        const extensions = e.graphQLErrors?.[0]?.extensions
        try {
          await dispatch('loginToV1', { extensions, e })
        }
        catch (e) {
          handleError(e)
        }
      }
    },
    async authorize ({ commit, dispatch }, token) {
      commit('SET_TOKEN', token)
      await dispatch('initUser')
    },
    async updateOnboarding ({ commit, dispatch, getters }, { onboardingStep, onboardingFinished } ) {
      const input = {
        ...(onboardingStep ? { onboardingStep } : {}),
        onboardingFinished
      }
      const { data: { updateMyProfile } } = await ApolloClient.mutate({ mutation: UPDATE_PROFILE, variables: { input } })
      await commit('UPDATE_USER', updateMyProfile)
    },
    async updateProfile ({ commit, dispatch }, user) {
      try {
        const {
          email,
          ...input
        } = user
        const { data: { updateMyProfile } } = await ApolloClient.mutate({
          mutation: UPDATE_PROFILE,
          variables: { input }
        })
        await commit('UPDATE_USER', updateMyProfile)
      }
      catch (e) {
        handleError(e)
      }
    },
    async uploadProfileAvatar ({ commit, dispatch }, file) {
      commit('SET_AVATAR_LOADING_STATUS', true)
      try {
        const { data: { uploadMyAvatarMediaImage } } = await ApolloClient.mutate({ mutation: UPLOAD_AVATAR_MEDIA, variables: { file }, context: { hasUpload: true } })
        dispatch('fetchProfileAvatar')
        return uploadMyAvatarMediaImage
      } catch (e) {
        handleError(e)
      }
    },
    async deleteProfileAvatar ({ commit }) {
      commit('SET_AVATAR_LOADING_STATUS', true)
      try {
        await ApolloClient.mutate({ mutation: DELETE_AVATAR_MEDIA })
        commit('SET_AVATAR_LOADING_STATUS', false)
        commit('SET_USER', { avatarMedia: [] })
      } catch (e) {
        handleError(e)
      }
    },
    async fetchProfileAvatar ({ commit, dispatch, state }) {
      const pollInterval = 1500
      const avatarSub = ApolloClient.watchQuery({ query: GET_PROFILE_AVATAR, pollInterval, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { getMyProfile: { avatarMedia = {} } }, loading }) => {
          if (!!avatarMedia.generatedMedia.length) {
            avatarSub.unsubscribe()
            commit('SET_USER', { avatarMedia })
            commit('SET_AVATAR_LOADING_STATUS', false)
          }
        })
    },
    async initUser ({ commit, dispatch, getters, rootGetters }, { skipWorkspaceSessionSet, workspaceId, preferableWorkspaceId } = {}) {
      const domain = getters.workspaceDomain
      await dispatch('setApplicationPublicConfig', null, {root: true})
      await dispatch('workspace/getAvailableWorkspaces', null, {root: true})
      await dispatch('getUserData')
      if (!domain && !skipWorkspaceSessionSet) {
        await dispatch('setWorkspaceForSession', { forcedWorkspaceId: workspaceId, preferableWorkspaceId })
      }
      await dispatch('setLanguage')
      commit('LOGIN_USER')
      dispatch('initIntercom')
      await dispatch('workspace/setWorkspace', null, {root: true})
      await dispatch('workspace/handleEDU', null, {root: true})
      if (rootGetters['workspace/isAdmin' || rootGetters['workspace/isOwner']]) {
        await dispatch('subscription/getPaymentMethod', null, { root: true })
      }
      await dispatch('groups/getAvailableStandardGroups', null, {root: true})
      await dispatch('groups/getAvailableSmartGroups', !rootGetters['workspace/smartGroupsEnabled'] ? true : null, {root: true})
    },
    async getUserData ({ commit }) {
      const { data: { getMyProfile } } = await ApolloClient.query({ query: LOGGED_IN_USER })
      commit('SET_USER', getMyProfile)
    },
    async getUserSessions ({commit, state}) {
      state.sessionsLoading = true
      try {
        const { data: { listMySessions } } = await ApolloClient.query({ query: LIST_SESSIONS, fetchPolicy: 'no-cache' })
        commit('SET_USER_SESSIONS', listMySessions)
        state.sessionsLoading = false
      } catch (e) {
        state.sessionsLoading = false
        handleError(e)
      }
    },
    async getPendingInviteByToken ({ commit }, token) {
      return ApolloClient.query({ query: GET_PENDING_INVITE_BY_TOKEN, variables: { token} })
    },
    async logOut ({ dispatch, commit }) {
      try {
        await ApolloClient.mutate({ mutation: LOGOUT_USER }).catch()
        await dispatch('resetUser')
        dispatch('shutDownIntercom')
      } catch (e) {
        handleError(e)
      }
    },
    async deleteUserSessionById ({ dispatch, commit }, sessionId) {
      try {
        await ApolloClient.mutate({ mutation: DELETE_SESSION_BY_ID, variables :{ sessionId} })
        await commit('DELETE_USER_SESSION_BY_ID', sessionId)
      } catch (e) {
        handleError(e)
      }
    },
    async resetUser ({ commit, dispatch }) {
      await ApolloClient.cache.reset()
      await commit('LOGOUT_USER')
      await dispatch('workspace/resetWorkspace', null, { root: true })
      await router.push({ name: 'Login' })
      recreateWsLink()
    },
    async resetUserPassword ({ commit }, { input, token }) {
      return ApolloClient.mutate({ mutation: RESET_USER_PASSWORD, variables: { input, token } })
    },
    async requestEmailChange ({ commit }, input) {
      return ApolloClient.mutate({ mutation: REQUEST_USER_EMAIL_CHANGE, variables: { input } })
    },
    async createCheckoutSession () {
      return ApolloClient.mutate({ mutation: CREATE_CHECKOUT_SESSION })
    },
    async changeEmail ({ commit }, token) {
      return ApolloClient.mutate({ mutation: CHANGE_USER_EMAIL, variables: { token } })
    },
    async requestPasswordReset ({ commit }, input) {
      return ApolloClient.mutate({ mutation: REQUEST_USER_PASSWORD_RESET, variables: { input } })
    },
    async revertEmail ({ commit }, token) {
      return ApolloClient.mutate({ mutation: REVERT_USER_EMAIL, variables: { token } })
    },
    async getWhitelabelSettings ({ commit }, whitelabel) {
      try {
        const { data: { getWorkspacePublicInfoByWhitelabel } } = await ApolloClient.query({ query: GET_WORKSPACE_PUBLIC_INFO_BY_WHITE_LABEL, variables: { whitelabel } })
        const settings = {
          logo: getWorkspacePublicInfoByWhitelabel?.whitelabelLogoMedia?.generatedMedia?.[0]?.url,
          favicon: getWorkspacePublicInfoByWhitelabel?.whitelabelFaviconMedia?.generatedMedia?.[0]?.url || getWorkspacePublicInfoByWhitelabel.whitelabelFaviconMedia?.url,
          accentColor: getWorkspacePublicInfoByWhitelabel?.settings?.whitelabelAccentColor || DEFAULT_ACCENT_COLOR
        }
        commit('SET_BASE_SETTINGS', settings)
      } catch (e) {
        handleError(e)
      }
    },
    async getWorkspaceIdByShortId ({ commit }, shortId) {
      try {
        const { data: { getWorkspacePublicInfoByShortId: {id, ssoAvailable} } } = await ApolloClient.query({ query: GET_WORKSPACE_ID_BY_SHORT_ID, variables: { shortId } })
        return {id, ssoAvailable}
      } catch (e) {
        handleError(e)
      }
    },
    async setWorkspaceForSession ({dispatch, rootGetters, getters}, { forcedWorkspaceId, preferableWorkspaceId }) {
      try {
        const savedWorkspaceId = await dispatch('workspace/getPreferredWorkspace', null, {root: true})
        const preferredWorkspaceId = forcedWorkspaceId || savedWorkspaceId || preferableWorkspaceId
        const availableWorkspaces = rootGetters['workspace/workspacesList']
        const workspaceIds = availableWorkspaces?.map(({id}) => id) || []
        let workspaceId
        if (preferredWorkspaceId && workspaceIds.includes(preferredWorkspaceId)) {
          workspaceId = preferredWorkspaceId
        }
        else {
          workspaceId = workspaceIds[0]
        }
        await ApolloClient.mutate({ mutation: SET_WORKSPACE_ID_FOR_SESSION, variables: { workspaceId } })
        dispatch('workspace/setPreferredWorkspace', workspaceId, {root: true})
      } catch (e) {
        console.log(e)
      }
    },
    async verifyEmail ({}, token) {
      return ApolloClient.mutate({ mutation: VERIFY_EMAIL, variables: { token } })
    },
    async submitEnterpriseRequestForm ({}, input) {
      return ApolloClient.mutate({ mutation: SUBMIT_ENTERPRISE_REQUEST_FORM, variables: { input } })
    },
    initIntercom ({ getters }) {
      getters.userEmail && initIntercom(getters.userEmail)
    },
    shutDownIntercom () {
      shutDownIntercom()
    },
    setLanguage ({commit, getters}, language) {
      localStorage.setItem('kc_lang', language || getters.language)
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    avatarLoading: state => state.avatarLoading,
    loggingIn: state => state.loggingIn,
    onboardingFinished: state => state.user?.onboardingFinished,
    onboardingStep: state => state.user?.onboardingStep || 0,
    user: state => state.user,
    userIsMigrated: state => state.user?.userCreatedBy === 'V1_MIGRATION',
    redirectingToV1: state => state.redirectingToV1,
    userIsComplete: state => !!(state.user
      && state.user.firstName
      && state.user.lastName
      && state.user.phone
      && state.user.desiredDevicesCount
      && state.user.companyName
      && state.user.companySize
      && state.user.companyWebsite
      && state.user.jobTitle),
    token: state => state.token,
    fullName: state => state.user ? `${state.user?.firstName} ${state.user?.lastName}`: '',
    phone: state => state.user?.phone,
    companyName: state => state.user?.companyName,
    userEmail: state => state.user?.email,
    ownedWorkspacesLimit: state => state.user?.features?.ownedWorkspacesLimit,
    workspaceDomain: state => state.workspaceDomain,
    userId: state => state.user?.id,
    is2faSet: state => state.user?.is2faSet,
    language: state => state.user?.language ? LOCALES_MAP[state.user?.language] : (localStorage.getItem('kc_lang') || navigator.language || 'en'),
    sessionsList: state => state.sessionsList,
    sessionsLoading: state => state.sessionsLoading,
    emailVerified: state => state.user?.emailVerified,
    logo: state => state.logo,
    favicon: state => state.favicon,
    accentColor: state => state.accentColor,
    windowBlurTime: state => state.windowBlurTime,
    avatar: state => size => {
      const { generatedMedia = [] } = state.user?.avatarMedia || {}
      const avatarObject = generatedMedia.find(m => m.tag === (size ? `avatar${size}x${size}` : 'content'))
      return avatarObject?.url || ''
    },
    avatarPlaceholder: state => {
      return getUserAvatarPlaceholder(state.user?.id)
    }
  },
  mutations: {
    SET_TOKEN_ONCE (state, token) {
      state.token = token
    },
    SET_TOKEN (state, token) {
      localStorage.setItem('auth-token', token)
      state.token = token
    },
    LOGIN_USER (state) {
      state.authStatus = true
    },
    SET_USER (state, user) {
      state.user = { ...state.user, ...user }
    },
    SET_AVATAR_LOADING_STATUS (state, status) {
      state.avatarLoading = status
    },
    SET_ACCENT_COLOR (state, accentColor) {
      if (state.accentColor) {
        state.accentColor = accentColor
      }
    },
    SET_LOGO (state, logo) {
      if (state.logo) {
        state.logo = logo
      }
    },
    SET_FAVICON (state, favicon) {
      if (state.favicon) {
        state.favicon = favicon
      }
    },
    SET_BASE_SETTINGS (state, { logo, favicon, accentColor }) {
      state.logo = logo
      state.favicon = favicon
      state.accentColor = accentColor
    },
    REMOVE_LOGO (state) {
      state.logo = null
    },
    REMOVE_FAVICON (state) {
      state.favicon = null
    },
    UPDATE_USER (state, user) {
      state.user = { ...state.user, ...user }
    },
    SET_LOGGING_IN_STATUS (state, status) {
      state.loggingIn = status
    },
    SET_USER_SESSIONS (state, sessions) {
      state.sessionsList = sessions
    },
    DELETE_USER_SESSION_BY_ID (state, sessionId) {
      state.sessionsList = [...state.sessionsList.filter(s => sessionId !== s.sessionId)]
    },
    SET_WINDOW_BLUR_TIME (state, windowBlurTime) {
      state.windowBlurTime = windowBlurTime
    },
    SET_WORKSPACE_DOMAIN (state, domain) {
      state.workspaceDomain = domain
    },
    LOGOUT_USER (state) {
      state.authStatus = false
      state.token = null
      state.sessionsList = null
      state.user = null
      localStorage.removeItem('auth-token')
    }
  }
}
